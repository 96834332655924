<template>
  <div>
    <Navigation nameColor="rgba(51, 51, 51, 1)"/>
    <div class="ai_bg_box">
      <div class="nav_box">
        <div class="null_box"></div>

        <img src="@/assets/ai/title.png" alt="">
        <el-tabs style="margin: 2rem 0;" v-loading.fullscreen.lock="fullscreenLoading" @tab-click="handleClick">
          <el-tab-pane :label="item" v-for="(item, index) in navList" :key="index"></el-tab-pane>
        </el-tabs>
        <!-- 二级菜单 -->
        <!-- 全部 -->
        <ul class="flex list" v-show="tabShow == '全部'">
          <li class="flex" @click="routePush(item)" v-for="(item, index) in menuAll" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <span id="free" v-show="item.free != ''"></span> -->
          </li>
        </ul>
        <!-- AI绘画 -->
        <ul class="flex list" v-show="tabShow == 'AI绘画'">
          <li class="flex" @click="routePush(item)" v-for="(item, index) in menu6" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <p></p> -->
          </li>
        </ul>
        <!-- AI图画 -->
        <ul class="flex list" v-show="tabShow == 'AI文书'">
          <li class="flex" @click="routePush(item)" v-for="(item, index) in menu7" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <p></p> -->
          </li>
        </ul>
      </div>
    </div>
    <footerBot/>
  </div>
</template>

<script>
export default {
  mounted() {
    this.menuAll.forEach(item => {
      let item_id = item.id
      if (item_id == 13 || item_id == 15 || item_id == 16) {
        this.menu6.push(item)
      } else if (item_id == 18 || item_id == 20 || item_id == 21) {
        this.menu7.push(item)
      }
    })
  },
  data() {
    return {
      navList: [
        '全部',
        'AI绘画',
        'AI文书'
      ],
      menuAll: [
        {
          id: 13,
          imgUrl: require('@/assets/ai/13.png'),
          title: 'AI绘画',
          path: '/paint'
        },
        {
          id: 15,
          imgUrl: require('@/assets/ai/15.png'),
          title: '黑白图像上色',
          path: '/blackWhite',
        },
        {
          id: 16,
          imgUrl: require('@/assets/ai/16.png'),
          title: '图片风格转换',
          path: '/transImg'
        },
        // {
        //   id: 17,
        //   imgUrl: require('@/assets/ai/17.png'),
        //   title: '人物头像动漫化',
        // },
        {
          id: 22,
          imgUrl: require('@/assets/ai/22.png'),
          title: '图片背景生成',
          path: '/image_matting'
        },
        {
          id: 23,
          imgUrl: require('@/assets/ai/23.png'),
          title: '一键抠图',
          path: '/change_back'
        },
        // {
        //   id: 24,
        //   imgUrl: require('@/assets/ai/24.png'),
        //   title: 'AI视频',
        // },
        {
          id: 18,
          imgUrl: require('@/assets/ai/18.png'),
          title: '律师函',
          path: '/lawyer'
        },
        // {
        //   id: 19,
        //   imgUrl: require('@/assets/ai/19.png'),
        //   title: '合同审查',
        // },
        {
          id: 20,
          imgUrl: require('@/assets/ai/20.png'),
          title: '版权侵权告知书',
          path: '/tort'
        },
        {
          id: 21,
          imgUrl: require('@/assets/ai/21.png'),
          title: '起诉书',
          path: '/prosecute',
        },
        {
          id: 24,
          imgUrl: require('@/assets/ai/24.png'),
          title: 'AI智能海报',
          path: '/placard',
        },
      ],
      menu6: [],
      menu7: [],
      tabShow: '全部',
      fullscreenLoading: false,//加载
    }
  },
  methods: {
    handleClick(tab, event) {
      this.tabShow = tab._props.label
      this.fullscreenLoading = false;
    },
    routePush(item) {
      this.$router.push(item.path)
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/wordOp';
</style>